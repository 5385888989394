<template>
  <div class="card">
    <div class="card-body">
      <h6 class="text-uppercase mb-3">{{$trans('Project Reports')}}</h6>
      <div v-if="results" class="table-responsive">
        <table class="table table-striped mb-0">
          <thead>
          <tr>
            <th class="text-center">{{$trans('STT')}}</th>
            <th>{{$trans('Project name')}}</th>
            <th>{{$trans('Group')}}</th>
            <th class="text-right">{{$trans('VND')}}</th>
            <th class="text-right">{{$trans('USD')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(result,k) in results" :key="k">
            <td class="text-center">{{k+1}}</td>
            <td>
              <slot v-if="result.project">
                {{result.project.name}}
              </slot>
            </td>
            <td>
              <slot v-if="result.project">
                {{result.project.group}}
              </slot>
            </td>
            <td class="text-right">
              <vue-numeric
                  currency-symbol-position="suffix"
                  currency=""
                  v-model="result.total"
                  v-bind:read-only="true"
                  v-bind:precision="0">
              </vue-numeric>
            </td>
            <td class="text-right">
              <vue-numeric
                  currency-symbol-position="suffix"
                  currency=""
                  v-model="result.total_usd"
                  v-bind:read-only="true"
                  v-bind:precision="0">
              </vue-numeric>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    filterby: String,
  },

  name: "ProjectReports",
  data:function () {
    return {
      resp_projects:null,
      results:null
    }
  },
  methods:{
    index: function () {
      let filterQuery = {
        filterby: this._props.filterby,
      }
      axios({url: this.$root.$data.api_url + '/api/dashboard/project-reports', params: filterQuery, method: 'GET'})
          .then(resp => {
            this.resp_projects = resp.data;
            this.results = resp.data.results;
          })
          .catch(err => {
            console.log(err)
          })
    }

  },
  created() {
    this.index()
  },
  watch: {
    filterby: function () {
      this.index()
    },
  },
  computed:{

  }
}
</script>
<style scoped>

</style>