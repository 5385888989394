import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'
import VueClipboard from 'vue-clipboard2'

import AxiosPlugin from 'vue-axios-cors';

Vue.use(AxiosPlugin)

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import device from "vue-device-detector"

Vue.use(device)

import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/vendor/fonts/boxicons.css'
import './assets/vendor/css/core.css'
import './assets/vendor/css/theme-default.css'
import './assets/vendor/css/pages/page-auth.css'
import './assets/css/demo.css'
import './assets/css/style.css'

import Paginate from 'vuejs-paginate'

Vue.component('paginate', Paginate)
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const lang = localStorage.getItem('lang');

if (lang) {
    Vue.prototype.$http.defaults.headers.common['language'] = lang;
}

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)
import i18n from './components/mixins/i18n.vue';
Vue.mixin(i18n);
import Helpers from './components/mixins/Helpers.vue';
Vue.mixin(Helpers);
import Datepicker from 'vuejs-datepicker';
Vue.use( Datepicker );
Vue.component("vuejs-datepicker", Datepicker);
import VueGoogleCharts from 'vue-google-charts'

Vue.use(VueGoogleCharts)
import VueGallery from 'vue-gallery'

Vue.component('gallery', VueGallery)


Vue.component('sidebar', require('./components/Sidebar.vue').default);
Vue.component('topBar', require('./components/TopBar.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('request-status', require('./components/RequestStatus.vue').default);
Vue.component('personal-information', require('./components/Auth/PersonalInformation').default);
Vue.component('widget-project-reports', require('./components/widgets/ProjectReports.vue').default);

new Vue({
    render: h => h(App),
    router,
    store,
    data: function () {
        return {
            api_url: process.env.VUE_APP_API_URL,
            app_name: process.env.VUE_APP_NAME,
            global_settings: {},
            user:null
        }
    },
    methods: {
        get_global_settings: function () {
            Axios({url: this.$root.$data.api_url + '/api/settings', params: {}, method: 'GET'})
                .then(resp => {
                    this.global_settings = resp.data;
                })
                .catch(err => {
                    console.log(err)
                })

        },
        get_user_info: function () {
            if (this.isLoggedIn) {
                Axios({url: this.$root.$data.api_url + '/api/profile', params: {}, method: 'GET'})
                    .then(resp => {
                        this.user = resp.data.user;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },

    },
    computed: {
        isMobile: function () {
            return this.$device.mobile;
        },
        current_currency: function () {
            return this.$store.getters.currency;
        },
        current_language: function () {
            return this.$store.getters.lang;
        },
        isLoggedIn:function () {
            return this.$store.getters.isLoggedIn
        },
        domain:function (){
            return window.location.origin
        },
        currentUser_permissions:function (){
            return localStorage.getItem('permissions')
        }
    },
    created() {
        Axios.interceptors.response.use((response) => {
            console.log('xx')
            return response;
        }, (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    let currentUrl = window.location
                    let domain = window.location.origin;
                    this.$store.dispatch('logout')
                   // this.$router.push('/login')
                    window.location.replace(domain+'/login?return_url='+currentUrl);
                }
            }
            throw  error;
        })
        this.get_global_settings();
        this.get_user_info()
    },
    mounted() {

    },
}).$mount('#app')
