<template>
    <div v-if="user">
      <div class="row">
        <div class="col-sm-6">
          <h6>{{$trans('Personal Information')}}</h6>
          <table class="table table-striped">
            <tbody>
            <tr>
              <th>{{$trans('Full name')}}</th>
              <td>{{user.name}}</td>
            </tr>
            <tr>
              <th>{{$trans('Email')}}</th>
              <td>{{user.email}}</td>
            </tr>
            <tr>
              <th>{{$trans('Roles')}}</th>
              <td>
                <slot v-if="user.roles">
                   <span v-for="(role,i) in user.roles" v-bind:key="i" class="mt-1 mr-1 badge badge-primary badge-pill">
                      {{role.name}}
                   </span>
                </slot>
              </td>
            </tr>
            <tr>
              <th>{{$trans('Telegram Connect')}}</th>
              <td>
                <span class="text-success" v-if="user.telegram_connected && user.telegram_connected ==1">{{$trans('Connected')}}</span>
                <slot v-else>
                  <a target="_blank" :href="'https://t.me/trolyseob4_bot?start='+user.username">{{$trans('Connect Now')}}</a>
                </slot>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6">
          <h6>{{$trans('Update Personal Information')}}</h6>
          <hr>
          <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
            {{alert.message}}
          </div>
          <div class="form-group">
            <label>{{$trans('Full name')}}</label>
            <input class="form-control" type="text" v-model="profile.name">
            <slot v-if="errors && errors.name">
              <div class="text-danger" v-for="error in errors.name" v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <div class="form-group">
            <label>{{$trans('Current password')}}</label>
            <input v-model="profile.current_password" type="password" class="form-control">
            <slot v-if="errors && errors.current_password">
              <div class="text-danger" v-for="error in errors.current_password"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <div class="form-group">
            <label>{{$trans('New password')}}</label>
            <input v-model="profile.password" type="password" class="form-control">
            <slot v-if="errors && errors.password">
              <div class="text-danger" v-for="error in errors.password" v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <button @click="save_profile" class="btn btn-primary">{{$trans('Save')}}</button>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PersonalInformation",
  data: function () {
    return {
      user: null,
      profile: {
        name: '',
        phone: '',
        current_password: '',
        password: ''
      },
      process: false,
      alert: null,
      errors: {}
    }
  },
  methods: {
    get_info: function () {
      axios({url: this.$root.$data.api_url + '/api/profile', method: 'GET'})
          .then(resp => {
            this.user = resp.data.user;
            this.profile.name = resp.data.user.name
            if( resp.data.user.phone){
              this.profile.phone = resp.data.user.phone;
            }

          })
          .catch(err => {
            console.log(err)
          })
    },
    save_profile: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/profile',data:this.profile, method: 'POST'})
            .then(resp => {
              this.process = false;
              const error_code = resp.data.error_code;
              const user = resp.data.user
              if (error_code == 0) {
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.$store.commit('update_profile', user.name)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};

              }
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }
    }
  },
  created() {
    this.get_info();
  }
}
</script>

<style scoped>

</style>