
<script>
    export default {
        name: "Helpers",
        data:function () {
            return {
                permissions_of_user:JSON.parse(localStorage.getItem('permissions')),
                roles_of_user:JSON.parse(localStorage.getItem('roles')),
            }
        },
        methods:{
            $can:function (permission) {
                if( !permission || permission.length == 0){
                  return  true
                }
                let can =false;
                if( Array.isArray(permission)){
                    permission.forEach((p)=>{
                        if(this.permissions_of_user.indexOf(p) !== -1){
                            can = true;
                            return false;
                        }
                        //return this.permissions_of_user.indexOf(p) !== -1;
                    });
                }else{
                    if(this.permissions_of_user.indexOf(permission) !== -1){
                        can = true;
                    }
                }
               return can;
            },
            $short_link:function (url){
              let text = url
              try {
                url = new URL(url.trim());
                url.protocol === "https:"
                return '<a href="'+url+'" target="_blank">'+text+'</a>'
              } catch (_) {
                if(this.validURL(url)){
                  return '<a href="https://'+url+'" target="_blank">'+text+'</a>'
                }
                return url;
              }

             /* if(this.validURL(url)){
                 return '<a href="'+url+'" target="_blank">'+url+'</a>'
              }
              return url;*/
            },
          validURL:function (urlString){
            var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
                '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
            return !!urlPattern.test(urlString);
          },
          $detail_title_text:function (type){
              switch (type){
                case 'Guest Post':
                case 'Tool':
                case 'Textlink':
                case 'Traffic users':
                case 'Domain':
                case 'Hosting':
                  return this.$trans('Domain')
                case 'Entity':
                case 'Backlink':
                  return this.$trans('Package')
                default:
                  return  this.$trans('Domain/Package/...')
              }
          },
          $detail_text_text:function (type){
            switch (type){
              case 'Guest Post':
                return this.$trans('Link File Content')
              case 'Textlink':
                return this.$trans('Anchor Text-Link')
              default:
                return  this.$trans('Note')
            }
          }
        }
    }
</script>