<template>
    <div class="profile">
        <h4 class="fw-bold mb-4 d-flex page-title">
            <span class="mr-auto">
              <span class="text-muted fw-light">{{$trans('User')}}/</span>
              {{$trans('Profile')}}
            </span>
        </h4>
      <nav>
        <ul class="nav nav-tabs" id="nav-tab" >
          <li class="nav-item">
            <a class="nav-link" @click="tabActive = 'profile'" v-bind:class="{'active':tabActive=='profile'}" href="javascript:void(0)">{{$trans('Profile')}}</a>
          </li>
        </ul>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div v-if="tabActive == 'profile'" class="tab-pane active">
          <personal-information></personal-information>
        </div>
      </div>

    </div>
</template>

<script>
    export default {
        name: "Profile",
      data:function (){
        return {
          tabActive:'profile'
        }
      }

    }
</script>

<style scoped>

</style>