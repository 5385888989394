<template>
    <div class="roles">
      <h4 class="fw-bold mb-4 d-flex page-title">
          <span class="mr-auto">
            {{$trans('Roles')}}
          </span>
        <button v-if="$can('add-roles')" @click="addForm = true" class="btn btn-primary btn-sm">
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
          {{$trans('Add new')}}
        </button>
      </h4>
      <div class="card">
          <div class="card-body">
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                      <tr>
                          <th>{{$trans('Name')}}</th>
                          <th>{{$trans('Permissions')}}</th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(role,index) in roles" v-bind:key="index">
                          <td>{{role.name}}</td>
                          <td style="white-space: normal;">
                              <span v-for="(permission,i) in role.permissions" v-bind:key="i" class="mt-1 mr-1 badge badge-primary badge-pill">
                                  {{permission.name}}
                              </span>
                          </td>
                          <td class="text-right td-control">
                            <a class="mr-1" v-if="$can('edit-roles')" @click="edit(role)" href="javascript:void(0)">
                              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                              </svg>
                            </a>
                            <a v-if="$can('delete-roles') && role.can_delete ==1" @click="destroy(role)" class="text-danger"
                               href="javascript:void(0)">
                              <svg data-v-7db115a6="" xmlns="http://www.w3.org/2000/svg"
                                   width="16" height="16" viewBox="0 0 24 24" fill="none"
                                   stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                   stroke-linejoin="round" class="feather feather-trash-2">
                                <polyline data-v-7db115a6="" points="3 6 5 6 21 6"></polyline>
                                <path data-v-7db115a6=""
                                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line data-v-7db115a6="" x1="10" y1="11" x2="10" y2="17"></line>
                                <line data-v-7db115a6="" x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </a>
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
      <modal v-if="addForm && $can('add-roles')" v-bind:width="'500px'" v-bind:height="'100vh'">
          <slot slot="body">
              <h5 class="mb-3">
                  {{$trans('Add role')}}
                  <button @click="addForm = false" class="close">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                  </button>
              </h5>
              <small>{{$trans('Fields Marked with (*) are required')}}</small>
              <hr>
              <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                  {{alert.message}}
              </div>
              <div class="form-group">
                  <label>{{$trans('Name')}} (*)</label>
                  <input v-model="addFields.name" class="form-control" type="text">
                  <slot v-if="errors && errors['name']">
                      <div class="text-danger" v-for="error in errors['name']"
                           v-bind:key="error">
                          <small>{{ error }}</small>
                      </div>
                  </slot>
              </div>
              <div class="form-group">
                  <label >{{$trans('Permissions')}} (*)</label>
                  <div class="permissions">
                      <div class="permission" v-for="(permission,i) in permissions" v-bind:key="i">
                          <div class="text-success mb-1">
                              <strong>{{$trans(permission.name)}}</strong>
                          </div>
                          <div class="form-check"  v-for="(per,slug) in permission.permissions" v-bind:key="slug">
                              <input v-bind:id="slug" type="checkbox" v-model="addFields.permissions" v-bind:value="slug">
                              <label class="form-check-label" v-bind:for="slug">
                                  <span>{{$trans(per)}}</span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
              <button @click="add_role" class="btn btn-primary">
                  <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{$trans('Add')}}
              </button>
          </slot>
      </modal>
      <modal v-if="editform && $can('edit-roles')" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Edit role')}}
                    <button @click="editform = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="updateFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label >{{$trans('Permissions')}} (*)</label>
                    <div class="permissions">
                        <div class="permission" v-for="(permission,i) in permissions" v-bind:key="i">
                            <div class="text-success mb-1">
                                <strong>{{$trans(permission.name)}}</strong>
                            </div>
                            <div class="form-check"  v-for="(per,slug) in permission.permissions" v-bind:key="slug">
                                <input v-bind:id="slug" type="checkbox" v-model="updateFields.permissions" v-bind:value="slug">
                                <label class="form-check-label" v-bind:for="slug">
                                    <span>{{$trans(per)}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="update_role" class="btn btn-primary">
                    <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
                    {{$trans('Update')}}
                </button>
            </slot>
        </modal>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "Roles",
        data:function () {
            return {
                addForm:false,
                editform:false,
                roles:null,
                addFields:{
                    name:'',
                    permissions:[]
                },
                process:false,
                permissions:[],
                errors:{},
                alert:null,
                updateFields:{}
            }
        },
        methods:{
            get_roles:function () {
                axios({url: this.$root.$data.api_url + '/api/roles', method: 'GET'})
                    .then(resp => {
                        this.roles = resp.data.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            get_permissions:function(){
                axios({url: this.$root.$data.api_url + '/api/permissions', method: 'GET'})
                    .then(resp => {
                        this.permissions = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            add_role:function(){
              if( this.process == false){
                  this.process = true;
                  this.errors = {}
                  this.alert = null
                  axios({url: this.$root.$data.api_url + '/api/roles', data:this.addFields,method: 'POST'})
                      .then(resp => {
                          let error_code = resp.data.error_code;
                          if( error_code == 0){
                              this.addForm = false;
                              this.get_roles();
                          }else{
                              this.alert = {
                                  type: 'warning',
                                  message: resp.data.message
                              }
                              this.errors = resp.data.errors || {};
                          }
                          this.process = false;
                      })
                      .catch(err => {
                          console.log(err)
                          this.process = false;
                      })
              }
            },
            update_role:function(){
                if( this.process == false){
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/roles/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if( error_code == 0){
                                this.editform = false;
                                this.get_roles();
                            }else{
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            edit:function (role) {
                axios({url: this.$root.$data.api_url + '/api/roles/'+role._id, method: 'GET'})
                    .then(resp => {
                        this.updateFields = resp.data.data;
                        this.editform = true;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            destroy:function (role) {
                var actionConfirm = confirm("Are you sure?");
                if (actionConfirm === false) {
                    return false;
                }
                axios({url: this.$root.$data.api_url + '/api/roles/' + role._id, method: 'DELETE'})
                    .then(() => {
                        this.get_roles();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        created() {
            this.get_roles();
            this.get_permissions();
        }
    }
</script>

<style scoped>

</style>