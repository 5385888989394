<template>
  <span :class="status_class">{{$trans(order_status[this._props.status])}}</span>
</template>
<script>
export default {
  props:{
    status: Number,
  },
  name: "RequestStatus",
  data:function () {
    return {

    }
  },
  computed:{
    order_status:function (){
      return this.$root.$data.global_settings.order_status
    },
    status_class:function (){
      let class_text ='badge badge-pill '
      switch (this._props.status){
        case 4:
          class_text = class_text+'badge-dark'
          break;
        case 3:
          class_text = class_text+'badge-info'
          break;
        case 2:
          class_text = class_text+'badge-primary'
          break;
        case 1:
          class_text = class_text+'badge-success'
          break;
        case 0:
          class_text = class_text+'badge-secondary'
          break;
        case -1:
          class_text = class_text+'badge-danger'
          break;
        case -2:
          class_text = class_text+'badge-warning'
          break;
        case -99:
          class_text = class_text+'badge-light'
          break;
      }
        return class_text
    }
  }
}
</script>

<style scoped>

</style>