<template>
    <div class="users">
        <h4 class="fw-bold mb-4 d-flex page-title">
            <span class="mr-auto">
              {{$trans('Users')}}
            </span>
            <button v-if="$can('add-users')" @click="addForm = true" class="btn btn-primary btn-sm mr-3">
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
              {{$trans('Add new')}}
            </button>
            <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
                <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
              </svg>
              {{ $trans('Filter') }}
            </button>
        </h4>
        <div v-if="formFilter" class="card mb-3">
        <div class="card-body">
          <div class="d-flex">
            <div class="form-group mr-3">
              <label>{{ $trans('Keyword') }}</label>
              <input v-model="filterQuery.keyword" :placeholder="'username,email,..'" type="text" class="form-control mr-3 w-300">
            </div>
            <div class="form-group mr-3">
              <label>{{ $trans('Status') }}</label>
              <select class="form-control w-200" v-model="filterQuery.status">
                <option value="0">{{ $trans('Inactive') }}</option>
                <option value="1">{{ $trans('Active') }}</option>
                <option value="-1">{{ $trans('Deleted') }}</option>
              </select>
            </div>
            <div class="form-group mr-3">
              <label>{{ $trans('Role') }}</label>
              <Select2 class="w-200" v-model="filterQuery.role_id"  :options="roles"/>
            </div>
          </div>
          <div class="filter-actions d-flex">
            <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
            <button @click="filter" class="btn btn-outline-primary btn-sm mr-3">
              <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
              {{ $trans('Apply') }}
            </button>
          </div>
        </div>
      </div>
        <div class="card">
            <div class="card-body">
                <slot v-if="users && users.data">
                    <div class="table-responsive">
                      <div class="list-info mb-3">
                        <small>{{$trans('Results')}}: <strong>{{users.data.length}}</strong> {{$trans('of')}} <strong>{{users.total}}</strong></small>
                      </div>
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>{{$trans('Name')}}</th>
                                <th>{{$trans('Username')}}</th>
                                <th>{{$trans('Roles')}}</th>
                                <th>{{$trans('Member since')}}</th>
                                <th>{{$trans('Status')}}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user,index) in users.data" v-bind:key="index">
                                <td>{{user.name}}</td>
                                <td>{{user.username}}</td>
                                <td>
                                  <span v-for="(role,i) in user.roles" v-bind:key="i" class="mt-1 mr-1 badge badge-primary badge-pill">
                                      {{role.name}}
                                  </span>
                                </td>
                                <td>{{user.created_at}}</td>
                                <td>
                                    <span v-if="user.status == 1" class="badge badge-primary badge-pill">{{$trans('Active')}}</span>
                                    <span v-if="user.status == 0" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
                                    <span v-if="user.status == '-1'" class="badge badge-danger badge-pill">{{$trans('Deleted')}}</span>
                                </td>
                              <td class="text-right td-control">
                                <a v-if="$can('edit-users')" @click="edit(user)" class="mr-1" href="javascript:void(0)">
                                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </a>
                                <a v-if="$can('delete-users')" @click="destroy(user)" class="text-danger" href="javascript:void(0)">
                                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                </a>
                              </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginate v-if="users && users.data && users.last_page"
                              :page-count="users.last_page"
                              :click-handler="paginate"
                              :prev-text="$trans('Previous')"
                              :next-text="$trans('Next')"
                              :page-class="'page-item'"
                              :prev-class="'page-item'"
                              :next-class="'page-item'"
                              :page-link-class="'page-link'"
                              :prev-link-class="'page-link'"
                              :next-link-class="'page-link'"
                              :container-class="'pagination'">
                    </paginate>
                </slot>

            </div>
        </div>
        <modal v-if="addForm && $can('add-users')" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Add user')}}
                    <button @click="addForm = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="addFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                  <label>{{$trans('Username')}} (*)</label>
                  <input v-model="addFields.username" class="form-control" type="text">
                  <slot v-if="errors && errors['username']">
                    <div class="text-danger" v-for="error in errors['username']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Password')}} (*)</label>
                    <input v-model="addFields.password" class="form-control" type="text">
                    <slot v-if="errors && errors['password']">
                        <div class="text-danger" v-for="error in errors['password']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label >{{$trans('Roles')}} (*)</label>
                    <Select2 v-model="addFields.roles" :options="roles" :settings="{  multiple:true}"  />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input id="login-locked" class="form-check-input" type="checkbox" v-model="addFields.status" true-value="1" false-value="0">
                    <label class="form-check-label" for="login-locked">
                      <span>{{$trans('Active')}}</span>
                    </label>
                  </div>
                </div>
                <button @click="add_user" class="btn btn-primary">
                    <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
                    {{$trans('Add')}}
                </button>
            </slot>
        </modal>
        <modal v-if="editform && $can('edit-users')" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Edit user')}}
                    <button @click="editform = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="updateFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                  <label>{{$trans('Username')}} (*)</label>
                  <input disabled v-model="updateFields.username" class="form-control" type="text">
                  <slot v-if="errors && errors['username']">
                    <div class="text-danger" v-for="error in errors['username']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Password')}} (*)</label>
                    <input v-model="updateFields.password" class="form-control" type="text">
                    <slot v-if="errors && errors['password']">
                        <div class="text-danger" v-for="error in errors['password']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label >{{$trans('Roles')}} (*)</label>
                    <Select2 v-model="updateFields.roles" :options="roles" :settings="{  multiple:true}"  />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input id="edit-form-active" class="form-check-input" type="checkbox" v-model="updateFields.status" true-value="1" false-value="0">
                    <label class="form-check-label" for="edit-form-active">
                      <span>{{$trans('Active')}}</span>
                    </label>
                  </div>
                </div>
                <button @click="update_user" class="btn btn-primary">
                    <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
                    {{$trans('Update')}}
                </button>
            </slot>
        </modal>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "Users",
        data:function () {
            return {
                tabActive:'balance',
                settingForm:false,
                users:null,
                user:null,
                addForm:false,
                editform: false,
                filterQuery:{
                    page:1,
                    keyword:'',
                    status:1,
                    role_id:'all'
                },
                addFields:{
                    name:'',
                    email:'',
                    password:'',
                    roles:[],
                    status:true,
                    username:''
                },
                errors:{},
                alert:null,
                response_roles:null,
                process:false,
                updateFields:{},
                formFilter:false,
                process_loading:false
            }
        },
        methods:{
           reset_filter: function () {
            this.filterQuery = {
              page:1,
              keyword:'',
              status:1,
              role_id:'all'
            }
            this.get_users()
          },
            filter:function (){
              this.filterQuery.page = 1;
              this.get_users();
            },
            paginate: function (pageNum) {
                this.filterQuery.page = pageNum;
                this.get_users();
            },
            get_users:function () {
                this.process_loading = true
                axios({url: this.$root.$data.api_url + '/api/users',params: this.filterQuery, method: 'GET'})
                    .then(resp => {
                        this.users = resp.data;
                      this.process_loading = false
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            add_user:function(){
                if( this.process == false){
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/users', data:this.addFields,method: 'POST'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if( error_code == 0){
                                this.addForm = false;
                                this.get_users();
                            }else{
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            get_roles:function(){
                axios({url: this.$root.$data.api_url + '/api/allRoles',params: {}, method: 'GET'})
                    .then(resp => {
                        this.response_roles = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            edit:function (user) {
                axios({url: this.$root.$data.api_url + '/api/users/'+user._id, method: 'GET'})
                    .then(resp => {
                        this.updateFields = resp.data.data;
                        this.editform = true;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            update_user:function(){
                if( this.process == false){
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/users/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if( error_code == 0){
                                this.editform = false;
                                this.get_users();
                            }else{
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            destroy:function (user) {
                var actionConfirm = confirm("Are you sure?");
                if (actionConfirm === false) {
                    return false;
                }
                axios({url: this.$root.$data.api_url + '/api/users/' + user._id, method: 'DELETE'})
                    .then(() => {
                        this.get_users();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            setting:function (user) {
              this.settingForm = true
              this.user = user
            }
        },
        created() {
            this.get_users();
            this.get_roles();
        },
        computed:{
            roles:function () {
                if( !this.response_roles){
                    return [];
                }
                let roles =[
                  {
                    id:'all',
                    text:'All'
                  }
                ];
                const  response_roles = this.response_roles;
                response_roles.forEach((el)=>{
                    let item ={
                        id:el.slug,
                        text:el.name
                    }
                    roles.push(item)
                })

                return  roles;
            }
        }
    }
</script>

<style scoped>

</style>