import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/Auth/Login.vue'
import ForgotPassword from './components/Auth/ForgotPassword.vue'
import ResetPassword from './components/Auth/ResetPassword.vue'
import Dashboard from './components/Dashboard.vue'
import Profile from './components/Profile.vue'
import Users from './components/Users.vue'
import Settings from './components/Settings.vue'
import Roles from './components/Roles.vue'


import store from './store'
import NProgress from 'nprogress'

Vue.use(NProgress)
import 'nprogress/nprogress.css'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
                title: process.env.VUE_APP_NAME + ' - ' + process.env.VUE_APP_SLUG,
                metaTags: []
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false,
                title: 'Login - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword,

            meta: {
                requiresAuth: false,
                title: 'Forgot password - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/reset-password/:token/:email',
            name: 'reset-password',
            component: ResetPassword,
            props: true,
            meta: {
                requiresAuth: false,
                title: 'Rest password - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                requiresAuth: true,
                title: 'Profile - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
            meta: {
                requiresAuth: true,
                title: 'Users - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true,
                title: 'Settings - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/roles',
            name: 'roles',
            component: Roles,
            meta: {
                requiresAuth: true,
                title: 'User Roles - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import("./components/Projects.vue"),
            meta: {
                requiresAuth: true,
                title: 'Projects - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/partners',
            name: 'partners',
            component: () => import("./components/Partners.vue"),
            meta: {
                requiresAuth: true,
                title: 'Partners - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/services',
            name: 'services',
            component: () => import("./components/Services.vue"),
            meta: {
                requiresAuth: true,
                title: 'Services - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/service/orders',
            name: 'service-orders',
            component: () => import("./components/Orders.vue"),
            meta: {
                requiresAuth: true,
                title: 'Order - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/orders',
            name: 'orders',
            component: () => import("./components/Orders.vue"),
            meta: {
                requiresAuth: true,
                title: 'Orders - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/payment',
            name: 'payment',
            component: () => import("./components/Payment.vue"),
            meta: {
                requiresAuth: true,
                title: 'Payment - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/service/request',
            name: 'service-request',
            component: () => import("./components/Request.vue"),
            meta: {
                requiresAuth: true,
                title: 'Request service - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import("./components/Reports.vue"),
            meta: {
                requiresAuth: true,
                title: 'Reports - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/settings/system',
            name: 'system-settings',
            component: () => import("./components/settings/System"),
            meta: {
                requiresAuth: true,
                title: 'System Settings - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/contents',
            name: 'contents',
            component: () => import("./components/Content.vue"),
            meta: {
                requiresAuth: true,
                title: 'Contents - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/players',
            name: 'players',
            component: () => import("./components/Players.vue"),
            meta: {
                requiresAuth: true,
                title: 'Players - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: () => import("./components/Tasks.vue"),
            meta: {
                requiresAuth: true,
                title: 'Tasks - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/daily-reports',
            name: 'daily-reports',
            component: () => import("./components/DailyReport.vue"),
            meta: {
                requiresAuth: true,
                title: 'Báo cáo hàng ngày - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },
        {
            path: '/checkin-reports',
            name: 'checkin-reports',
            component: () => import("./components/CheckInReports.vue"),
            meta: {
                requiresAuth: true,
                title: 'Chấm công - ' + process.env.VUE_APP_NAME,
                metaTags: []
            }
        },

        {
            path: "*",
            component: () => import("./components/NotFound.vue"),
            meta: {
                title: 'Not Found',
                metaTags: []
            }
        },

    ]
})

export default router

router.beforeEach((to, from, next) => {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach(tag => document.head.appendChild(tag));

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    NProgress.done();
    const el = document.getElementById('main-content');
    if (el) {
        el.classList.remove('open-menu');
    }


});