<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo bg-primary text-light">
      <router-link class="app-brand-link" to="/">
        <span class="app-brand-text demo menu-text fw-bolder text-light">{{ app_name }}</span>
      </router-link>
      <a @click="closeNav" href="javascript:void(0);"   class=" layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
    <div class="menu-inner-shadow"></div>
    <ul class="menu-inner py-1">
      <li v-for="(menu,k) in main_menus" :key="k" class="menu-item"
          v-bind:class="{'open':menu.show,'active open':menuActive(menu.links)}">
        <slot v-if="$can(menu.permissions)">
          <a v-if="menu.subs && menu.subs.length" @click="collapse(k)" href="javascript:void(0);"
             class="menu-link menu-toggle">
            <span v-html="menu.icon"></span>
            <div>{{ $trans(menu.text) }}</div>
          </a>
          <slot v-else>
            <router-link v-if="$can(menu.permissions)" class="menu-link" :to="menu.link">
              <span v-html="menu.icon"></span>
              <div>{{ $trans(menu.text) }}</div>
            </router-link>
          </slot>
          <ul v-if="menu.subs && menu.subs.length" class="menu-sub">
            <li v-for="(sub,i) in menu.subs" :key="i" class="menu-item"
                v-bind:class="{'active':menuActive([sub.link])}">
              <router-link v-if="$can(sub.permissions)" class="menu-link" :to="sub.link">
                <div>{{ $trans(sub.text) }}</div>
              </router-link>
            </li>
          </ul>
        </slot>
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  name: "Sidebar",
  data: function () {
    return {
      app_name: process.env.VUE_APP_NAME,
      menus: [
        {
          show: false,
          link: '/users',
          text: 'Users',
          icon: '<i class="menu-icon tf-icons bx bx-user"></i>',
          permissions: [],
          subs: [
            {
              link: '/users',
              text: 'Users',
              permissions: ['view-users', 'add-users', 'edit-users', 'delete-users'],
            },
            {
              link: '/roles',
              text: 'Roles',
              permissions: ['view-roles', 'add-roles', 'edit-roles', 'delete-roles'],
            },
          ]
        },
        {
          show: false,
          link: '/projects',
          text: 'Projects',
          icon: '<i class="menu-icon tf-icons bx bx-notepad"></i>',
          permissions: ['project-manager'],
          subs: []
        },
        {
          show: false,
          link: '/partners',
          text: 'Partners',
          icon: '<i class="menu-icon tf-icons bx bx-user-pin"></i>',
          permissions: ['partner-manager'],
          subs: []
        },
        {
          show: false,
          link: '/orders',
          text: 'Orders',
          icon: '<i class="menu-icon tf-icons bx bx-cart-alt"></i>',
          permissions: ['confirm-orders','view-orders'],
          subs: []
        },
        {
          show: false,
          link: '/payment',
          text: 'Payment',
          icon: '<i class="menu-icon tf-icons bx bx-credit-card-front"></i>',
          permissions: ['payment-orders'],
          subs: []
        },
        {
          show: false,
          link: '/services',
          text: 'Services',
          icon: '<i class="menu-icon tf-icons bx bxs-plane"></i>',
          permissions: [],
          subs: [
            {
              link: '/services',
              text: 'Services',
              permissions: ['view-services'],
            },
            {
              link: '/service/request',
              text: 'Request',
              permissions: ['request-services'],
            },
            {
              link: '/contents',
              text: 'Web Contents',
              permissions: ['content-manager'],
            },
          ]
        },
        {
          show: false,
          link: '/tasks',
          text: 'Tasks',
          icon: '<span class="menu-icon"><svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg></span>',
          permissions: ['add-task','all-tasks','view-tasks','it-tasks','content-tasks','design-tasks','tools-tasks'],
          subs: []
        },
        {
          show: false,
          link: '/daily-reports',
          text: 'Daily Reports',
          icon: '<span class="menu-icon"><svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg></span>',
          permissions: [],
          subs: []
        },
        {
          show: false,
          link: '/checkin-reports',
          text: 'Check-In',
          icon: '<span class="menu-icon"><svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg></span>',
          permissions: ['check-in-report'],
          subs: []
        },
        {
          show: false,
          link: '/reports',
          text: 'Reports',
          icon: '<i class="menu-icon tf-icons bx bx-line-chart"></i>',
          permissions: ['view-my-reports'],
          subs: []
        },
      ],
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    collapse: function (key) {
      let menus = this.menus
      let new_menus = []
      menus.forEach((el, k) => {
        let item = el
        item.show = false
        if (k == key) {
          item.show = !item.show
          if (el.subs.length) {
            let link = el.subs[0]['link']
            this.$router.push(link)
          }
        }
        new_menus.push(item)
      })
      this.menus = new_menus
    },
    menuActive: function (path) {
      if (path) {
        let routePath = this.$route.path;
        if (path.includes(routePath)) {
          return true
        }
      }
      return false
    },
    toggleTagClass: function (addRemoveClass, tagId, className) {
      const el = document.getElementById(tagId);
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    openNav: function () {
      this.toggleTagClass('addClass', 'main-content', 'open-menu');
    },
    closeNav: function () {
      this.showSidebar = false
      this.toggleTagClass('remove', 'main-content', 'open-menu');
    },
  },
  created() {
    this.menuActive()
  },
  computed: {
    main_menus: function () {
      let menu = this.menus
      let main_menus = []
      menu.forEach((el) => {
        let item = el
        let links = []
        if (item.link != 'javascript:void(0)') {
          links.push(item.link)
        }
        let main_permissions = el.permissions
        if (el.subs && el.subs.length) {
          el.subs.forEach((sub) => {
            links.push(sub.link)
            let sub_permissions = sub.permissions
            if (sub_permissions.length) {
              sub_permissions.forEach((p) => {
                main_permissions.push(p)
              })
            }
          })
        }
        item.links = links
        item.permissions = main_permissions
        main_menus.push(item)
      })
      return main_menus;
    }
  }
}
</script>

<style scoped>

</style>