<template>
    <div class="settings">
      <h4 class="fw-bold mb-4 d-flex page-title">
          <span class="mr-auto">
            <span class="text-muted fw-light">{{$trans('System')}}/</span>
            {{$trans('Settings')}}
          </span>
      </h4>
      <div class="ds">
        <nav>
          <ul class="nav nav-tabs" id="nav-tab" >
            <li class="nav-item">
              <a class="nav-link" @click="tabActive = 'system'" v-bind:class="{'active':tabActive=='system'}" href="javascript:void(0)">{{$trans('System')}}</a>
            </li>
            <li class="nav-item">
              <a v-if="$can('fee-settings')" class="nav-link" @click="tabActive = 'fee'" v-bind:class="{'active':tabActive=='fee'}" href="javascript:void(0)">{{$trans('Fee')}}</a>
            </li>
            <li class="nav-item">
              <a class="n nav-link" @click="tabActive = 'merchant'" v-bind:class="{'active':tabActive=='merchant'}" href="javascript:void(0)">{{$trans('API')}}</a>
            </li>
          </ul>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div v-if="tabActive == 'merchant'" class="tab-pane active">
            <merchant-settings></merchant-settings>
          </div>
          <div v-if="tabActive == 'system'" class="tab-pane active">
            <system-settings></system-settings>
          </div>
          <div v-if="tabActive == 'fee'" class="tab-pane active">
            <fee-settings></fee-settings>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "Settings",
        data:function () {
            return {
                tabActive:'system'
            }
        }
    }
</script>

<style scoped>

</style>