<template>
  <div class="dashboard">
    <slot v-if="$can('view-dashboard')">
      <h4 class="fw-bold mb-4 d-flex page-title">
        <span class="mr-auto" style="line-height: 38px;">
          {{ $trans('Dashboard') }}
        </span>
        <div class="d-flex">
          <select v-model="filterQuery.filterby" class="custom-select w150" @change="index">
            <option value="today">{{ $trans('Today') }}</option>
            <option value="yesterday">{{ $trans('Yesterday') }}</option>
            <option value="month">{{ $trans('This Month') }}</option>
            <option value="last_month">{{ $trans('Last Month') }}</option>
            <option value="year">{{ $trans('This Year') }}</option>
          </select>
        </div>
      </h4>
      <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col">
                <div class="card h-100 mb-">
                  <div class="card-body mb-3">
                    <div class="row align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                          {{ $trans('Total Request') }}
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-while-800">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency=""
                              v-bind:value="report_data.total_request"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </div>
                        <div class="mt-2 mb-0 text-muted text-xs">
                          {{$trans('Total request')}}
                        </div>
                      </div>
                      <div class="col-auto text-primary">
                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 mb-0">
                  <div class="card-body mb-3">
                    <div class="row align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                          {{ $trans('Request Success') }}
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-while-800">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency=""
                              v-bind:value="report_data.success_request"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </div>
                        <div class="mt-2 mb-0 text-muted text-xs">
                          {{$trans('Request has done')}}
                        </div>
                      </div>
                      <div class="col-auto text-success">
                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 mb-0">
                  <div class="card-body mb-3">
                    <div class="row align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                          {{ $trans('Spent') }}
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-while-800">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency="VND"
                              v-bind:value="report_data.spent"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                          <br>
                         <span class="text-success">
                            <vue-numeric
                                currency-symbol-position="suffix"
                                currency="USD"
                                v-bind:value="report_data.spent_usd"
                                v-bind:read-only="true"
                                v-bind:precision="0">
                          </vue-numeric>
                         </span>
                        </div>
                        <div class="mt-2 mb-0 text-muted text-xs">
                          {{$trans('Total spent')}}
                        </div>
                      </div>
                      <div class="col-auto text-danger">
                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 mb-0">
                  <div class="card-body mb-3">
                    <div class="row align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                          {{ $trans('Pending Request') }}
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-while-800">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency=""
                              v-bind:value="report_data.pending_request"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </div>
                        <div class="mt-2 mb-0 text-muted text-xs">
                          {{$trans('Pending request')}}
                        </div>
                      </div>
                      <div class="col-auto text-secondary">
                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 mb-0">
                  <div class="card-body mb-3">
                    <div class="row align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-uppercase mb-1">
                          {{ $trans('Processing Request') }}
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-while-800">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency=""
                              v-bind:value="report_data.processing_request "
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </div>
                        <div class="mt-2 mb-0 text-muted text-xs">
                          {{$trans('Processing request')}}
                        </div>
                      </div>
                      <div class="col-auto text-info">
                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-3">
              <div class="card-body">
                <slot v-if="results && results.length && charts">
                  <GChart
                      type="LineChart"
                      :data="report_data.chart_data"
                      :options="charts.options"
                  />
                </slot>
                <slot v-else>
                  {{ $trans('No data') }}
                </slot>
              </div>
            </div>

          </div>
          <div class="col-sm-4">
            <widget-project-reports class="mb-3" :filterby="filterQuery.filterby"></widget-project-reports>
          </div>
      </div>

    </slot>
    <slot v-else>
      {{ $trans('Welcome to Admin Panel') }}
    </slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Dashboard",
  data: function () {
    return {
      user: null,
      filterQuery: {
        filterby: 'month'
      },
      statics_data:null,
      results:null,
      charts:null
    }
  },
  methods: {
    index: function () {
      this.process = true
      axios({url: this.$root.$data.api_url + '/api/dashboard/statistic', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.statics_data = resp.data;
            this.results = resp.data.results;
            this.charts = resp.data.charts;
            this.process = false
          })
          .catch(err => {
            console.log(err)
            this.process = false
          })

    },
  },
  created() {
    this.index()
  },
  computed: {
    report_data: function () {
      if (!this.results) {
        return [];
      }
      let data = this.results
      let chart_data = [
        ['Time', 'Request', 'Spent']
      ]
      let total_request = 0;
      let spent = 0;
      let spent_usd = 0;
      let processing_request = 0;
      let pending_request = 0;
      let success_request = 0;
      data.forEach((el) => {
        total_request = total_request+el.total_request
        spent = spent + el.spent
        spent_usd = spent_usd + el.spent_usd
        success_request = success_request + el.success_request
        processing_request = processing_request + el.processing_request
        pending_request = pending_request + el.pending_request
        let item_chart_data = [
          el.vAxisTitle,
          el.total_request,
          el.spent,
        ]
        chart_data.push(item_chart_data);
      })
      return {
        chart_data: chart_data,
        total_request: total_request,
        spent: spent,
        spent_usd: spent_usd,
        pending_request:pending_request,
        success_request:success_request,
        processing_request:processing_request
      }

    },

  }
}
</script>

<style scoped>

</style>