<template>
  <div id="main-content" v-if="isLoggedIn" class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
        <sidebar></sidebar>
        <div class="layout-page">
          <top-bar></top-bar>
          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
                <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div v-else class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
          <div class="authentication-inner">
            <router-view></router-view>
          </div>
      </div>
  </div>
</template>
<script>
  export default {
    name: 'App',
    data: function () {
      return {
        app_name:process.env.VUE_APP_NAME,
      }
    },
    computed: {
      isLoggedIn: function () {
        return this.$store.getters.isLoggedIn
      },
      user:function () {
        return this.$store.getters.user
      },
      current_lang:function () {
        return this.$store.getters.lang;
      },
      current_language: function () {
        return this.$root.current_language
      }
    },
    methods: {
      change_language: function (lang) {
        this.$store.dispatch('setLang', lang)
        this.showLanguages = false
      },
    },
    created() {

    },
  }
</script>

<style>

</style>